/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import styled, { createGlobalStyle } from "styled-components"

import "./layout.css"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalCss/>
      <main>{children}</main>
      <Footer/>
      <CookieConsent location="bottom" buttonText="I agree" cookieName="cookie-consent" style={{
        background: "#363839",
        fontFamily: "Ubuntu, sans-serif",
      }} buttonStyle={{ color: "#000", fontSize: "1.1rem", fontFamily: "Ubuntu, sans-serif" }} expires={365}>
        <CookieConsentText>
          <Link to="/policies/cookies">Cookies</Link> help us deliver our services. By using our services, you agree to our use of
          cookies. <Link to="/policies/cookies">Learn more.</Link>
        </CookieConsentText>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const GlobalCss = createGlobalStyle`
  body {
    background: #363839;
    font-family: Ubuntu, sans-serif;
    font-size: 15px;
    
    @media (min-width: 767px) {
      font-size: 17px;
    }
    
    @media (min-width: 992px) {
      font-size: 19px;
    }
    
    @media (min-width: 1200px) {
      font-size: 21px;
    }
    
    @media (min-width: 2000px) {
      font-size: 24px;
    }
  }
  
  main {
    background: #ffffff;
  }
  
  ::selection {
    background: #F54325;
    color: #efefef;
  }
`

const CookieConsentText = styled.div`
  display: block;
  
  a {
    color: #ffffff;
  }
`

export default Layout
