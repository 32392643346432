import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.15em;
  background: #363839;
  color: #8c8989;
  font-size: 0.85em;
  text-align: center;
  padding: 2em;
  font-family: Ubuntu, sans-serif;
  
  &::selection, strong::selection {
    color: #ffffff;
  }
`

const Footer = function() {
  return (
    <StyledFooter>
      <div>
	      <strong>Splendido Solutions d.o.o.</strong>
      </div>
	    <div>
		    Stjepana Vukovića 8b, 42000 Varaždin, Croatia
	    </div>
	    <div>
		    OIB: 42172723674
	    </div>
	    <div>
		    IBAN: LT28 3250 0782 3329 7239
	    </div>
	    <div>
		    &copy; 2023
	    </div>
    </StyledFooter>
  )
}

export default Footer
